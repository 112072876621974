<template>
  <div class="left-box">
    <div class="left-logo">
      <img src="../../public/images/htbiglogo.png">
    </div>
    <el-menu
      class="left-list"
      :default-openeds="defaultOpenedsArray"
      :default-active="this.$route.path"
      background-color="#004483"
      text-color="#fff"
      active-text-color="#f5f5f5"
      :unique-opened="true"
      @open="submenuEvent"
      router>
    
      <el-menu-item index="/" class="mt5" @click="closeItem(),Getname('首页')" v-if="leftind.includes('1')">
        <span slot="title" class="Meteorological-run">首页</span>
      </el-menu-item>

      <el-submenu index="1" class="mt5" v-if="leftind.includes('2')">
        <template slot="title">
          <span class="course-run">课程管理</span>
        </template>
        <el-menu-item index="/courselist" @click="Getname('课程管理 > 课程列表')" v-if="leftind.includes('3')">课程列表</el-menu-item>
        <el-menu-item index="/recoverylist" @click="Getname('课程管理 > 回收站')" v-if="leftind.includes('4')">回收站</el-menu-item>
        <el-menu-item index="/trainingfield" @click="Getname('课程管理 > 培训领域')" v-if="leftind.includes('5')">培训领域</el-menu-item>
        <el-menu-item index="/teacherlist" @click="Getname('课程管理 > 老师列表')" v-if="leftind.includes('6')">老师列表</el-menu-item>
      </el-submenu>

      <el-submenu index="2" class="mt5" v-if="leftind.includes('7')">
        <template slot="title">
          <span class="examination-run">考试管理</span>
        </template>
        <el-menu-item index="/paperlist" @click="Getname('考试管理 > 试卷管理')" v-if="leftind.includes('8')">试卷管理</el-menu-item>
        <el-menu-item index="/questionlist" @click="Getname('考试管理 > 题库管理')" v-if="leftind.includes('9')">题库管理</el-menu-item>
      </el-submenu>

      <el-menu-item index="/certificatelist" class="mt5" @click="closeItem(),Getname('证书管理')" v-if="leftind.includes('10')">
        <span slot="title" class="certificate-run">证书管理</span>
      </el-menu-item>

      <el-menu-item index="/studentlist" class="mt5" @click="closeItem(),Getname('学员管理')" v-if="leftind.includes('11')">
        <span slot="title" class="student-run">学员管理</span>
      </el-menu-item>

      <el-menu-item index="/classlist" class="mt5" @click="closeItem(),Getname('班级管理')" v-if="leftind.includes('12')">
        <span slot="title" class="Class-run">班级管理</span>
      </el-menu-item>

      <el-submenu index="3" class="mt5" v-if="leftind.includes('13')">
        <template slot="title">
          <span class="content-run">内容管理</span>
        </template>
        <el-menu-item index="/aboutus" @click="Getname('内容管理 > 关于我们')" v-if="leftind.includes('14')">关于我们</el-menu-item>
        <el-menu-item index="/instructions" @click="Getname('内容管理 > 使用说明')" v-if="leftind.includes('15')">使用说明</el-menu-item>
        <el-menu-item index="/agreement" @click="Getname('内容管理 > 用户协议')" v-if="leftind.includes('16')">用户协议</el-menu-item>
        <el-menu-item index="/privacyagree" @click="Getname('内容管理 > 隐私协议')" v-if="leftind.includes('17')">隐私协议</el-menu-item>
      </el-submenu>

      <el-menu-item index="/message" class="mt5" @click="closeItem(),Getname('消息管理')" v-if="leftind.includes('18')">
        <span slot="title" class="message-run">消息管理</span>
      </el-menu-item>

      <el-submenu index="4" class="mt5" v-if="leftind.includes('19')">
        <template slot="title">
          <span class="system-run">系统配置</span>
        </template>
        <el-menu-item index="/friendship" @click="Getname('系统配置 > 友情链接')" v-if="leftind.includes('20')">友情链接</el-menu-item>
        <el-menu-item index="/contactus" @click="Getname('系统配置 > 联系我们')" v-if="leftind.includes('21')">联系我们</el-menu-item>
        <el-menu-item index="/carousel" @click="Getname('系统配置 > 轮播管理')" v-if="leftind.includes('22')">轮播管理</el-menu-item>
        <el-menu-item index="/certtemplate" @click="Getname('系统配置 > 证书模板')" v-if="leftind.includes('23')">证书模板</el-menu-item>
      </el-submenu>

      <el-submenu index="5" class="mt5" v-if="leftind.includes('24')">
        <template slot="title">
          <span class="manage-run">管理员管理</span>
        </template>
        <el-menu-item index="/accountment" @click="Getname('管理员管理 > 账号管理')" v-if="leftind.includes('25')">账号管理</el-menu-item>
        <el-menu-item index="/rolelist" @click="Getname('管理员管理 > 角色设置')" v-if="leftind.includes('26')">角色设置</el-menu-item>
      </el-submenu>
    </el-menu>
  </div>
</template>

<script>
  export default {
    name: 'LeftColumn',
    data(){
      return{
        defaultOpenedsArray:[],
        leftind: localStorage.store.split(',')
      }
    },
    methods:{
      Getname(name){
        this.bus.$emit('name',name)
      },
      
      closeItem(){
        this.defaultOpenedsArray = []
      },

      submenuEvent(key){
        this.defaultOpenedsArray[0] = key
      }
    }
  }
</script>

<style>
  .left-box{
    width: 170px;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 68, 131, 1);
    /* background: #323232; */
    height: 100%;
    overflow: auto;
    border-radius: 0 50px 20px 0;
  }

  .left-box::-webkit-scrollbar{
    width: 0;
  }

  .left-box .el-menu{
    width: 169px;
  }

  .left-logo{
    padding-bottom: 20px;
    border-bottom: 1px solid #646464;
  }

  .left-logo img{
    width: 43px;
    height: 43px;
    background: #fff;
    border-radius: 50%;
    margin-left: 14px;
    margin-top: 24px;
  }

  .left-list{
    height: 85%;
    font-size: 18px;
  }

  
  
  .Meteorological-run{
    background: url(../../public/images/shouye.png) left center  no-repeat;
    background-size: 14px 14px;
    padding-left: 25px;
  }

  .left-list .el-menu-item:hover .Meteorological-run{
    background: url(../../public/images/shouyexz.png) left center  no-repeat;
    background-size: 14px 14px;
    color: rgb(0, 0, 0);
  }

  .is-active .Meteorological-run{
    background: url(../../public/images/shouyexz.png) left center  no-repeat;
    background-size: 14px 14px;
    color: rgb(0, 0, 0);
  }

  .course-run{
    background: url(../../public/images/kcgl.png) left center  no-repeat;
    background-size: 14px 14px;
    padding-left: 25px;
  }

  .left-list .el-submenu__title:hover .course-run{
    background: url(../../public/images/keglxz.png) left center  no-repeat;
    background-size: 14px 14px;
    color: rgb(0, 0, 0);
  }

  .left-list .is-opened .course-run{
    background: url(../../public/images/keglxz.png) left center  no-repeat;
    background-size: 14px 14px;
    color: rgb(0, 0, 0);
  }

  .examination-run{
    background: url(../../public/images/ksgl.png) left center  no-repeat;
    background-size: 14px 14px;
    padding-left: 25px;
  }

  .left-list .el-submenu__title:hover .examination-run{
    background: url(../../public/images/ksglxz.png) left center  no-repeat;
    background-size: 14px 14px;
    color: rgb(0, 0, 0);
  }

  .left-list .is-opened .examination-run{
    background: url(../../public/images/ksglxz.png) left center  no-repeat;
    background-size: 14px 14px;
    color: rgb(0, 0, 0);
  }

  .certificate-run{
    background: url(../../public/images/zsgl.png) left center  no-repeat;
    background-size: 14px 14px;
    padding-left: 25px;
  }

  .left-list .el-menu-item:hover .certificate-run{
    background: url(../../public/images/zsglxz.png) left center  no-repeat;
    background-size: 14px 14px;
    color: rgb(0, 0, 0);
  }

  .is-active .certificate-run{
    background: url(../../public/images/zsglxz.png) left center  no-repeat;
    background-size: 14px 14px;
    color: rgb(0, 0, 0);
  }

  .student-run{
    background: url(../../public/images/xygl.png) left center  no-repeat;
    background-size: 14px 14px;
    padding-left: 25px;
  }

  .left-list .el-menu-item:hover .student-run{
    background: url(../../public/images/xyglxz.png) left center  no-repeat;
    background-size: 14px 14px;
    color: rgb(0, 0, 0);
  }

  .is-active .student-run{
    background: url(../../public/images/xyglxz.png) left center  no-repeat;
    background-size: 14px 14px;
    color: rgb(0, 0, 0);
  }

  .Class-run{
    background: url(../../public/images/bjgl.png) left center  no-repeat;
    background-size: 14px 14px;
    padding-left: 25px;
  }

  .left-list .el-menu-item:hover .Class-run{
    background: url(../../public/images/bjglxz.png) left center  no-repeat;
    background-size: 14px 14px;
    color: rgb(0, 0, 0);
  }

  .is-active .Class-run{
    background: url(../../public/images/bjglxz.png) left center  no-repeat;
    background-size: 14px 14px;
    color: rgb(0, 0, 0);
  }

  .content-run{
    background: url(../../public/images/nrgl.png) left center  no-repeat;
    background-size: 14px 14px;
    padding-left: 25px;
  }

  .left-list .el-submenu__title:hover .content-run{
    background: url(../../public/images/nrglxz.png) left center  no-repeat;
    background-size: 14px 14px;
    color: rgb(0, 0, 0);
  }

  .left-list .is-opened .content-run{
    background: url(../../public/images/nrglxz.png) left center  no-repeat;
    background-size: 14px 14px;
    color: rgb(0, 0, 0);
  }

  .message-run{
    background: url(../../public/images/xxgl.png) left center  no-repeat;
    background-size: 14px 14px;
    padding-left: 25px;
  }

  .left-list .el-menu-item:hover .message-run{
    background: url(../../public/images/xxglxz.png) left center  no-repeat;
    background-size: 14px 14px;
    color: rgb(0, 0, 0);
  }

  .is-active .message-run{
    background: url(../../public/images/xxglxz.png) left center  no-repeat;
    background-size: 14px 14px;
    color: rgb(0, 0, 0);
  }
  
  .system-run{
    background: url(../../public/images/xtpz.png) left center  no-repeat;
    background-size: 14px 14px;
    padding-left: 25px;
  }

  .left-list .el-submenu__title:hover .system-run{
    background: url(../../public/images/xtpzxz.png) left center  no-repeat;
    background-size: 14px 14px;
    color: rgb(0, 0, 0);
  }

  .left-list .is-opened .system-run{
    background: url(../../public/images/xtpzxz.png) left center  no-repeat;
    background-size: 14px 14px;
    color: rgb(0, 0, 0);
  }

  .manage-run{
    background: url(../../public/images/gly.png) left center  no-repeat;
    background-size: 14px 14px;
    padding-left: 25px;
  }

  .left-list .el-submenu__title:hover .manage-run{
    background: url(../../public/images/glyxz.png) left center  no-repeat;
    background-size: 14px 14px;
    color: rgb(0, 0, 0);
  }

  .left-list .is-opened .manage-run{
    background: url(../../public/images/glyxz.png) left center  no-repeat;
    background-size: 14px 14px;
    color: rgb(0, 0, 0);
  }

  .mt5{
    margin-top: 5px !important;
  }


  .left-list .el-submenu .el-menu-item{
    background: #f5f5f5 !important;
    color: #646464 !important;
    padding-left: 60px !important;
  }

  .left-list .el-menu-item:hover{
    background: rgb(245, 245, 245) !important;
    color: #000 !important;
  }
  .left-list .el-menu-item.is-active{
    background: rgb(245, 245, 245) !important;
    color: #000 !important;
  }
  .left-list .is-opened .el-submenu__title{
    background: rgb(245, 245, 245) !important;
    color: #000 !important;
  }
  .left-list .el-submenu__title:hover{
    background: rgb(245, 245, 245) !important;
    color: #000 !important;
  }
</style>
