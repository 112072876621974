<template>
  <div id="app">
    <router-view></router-view>
    <!-- <el-container direction="vertical">
      <all-header></all-header>

      <el-container>
        <el-aside  width="200px">
          <left-column></left-column>
        </el-aside>

        <el-main class="admin-right">
          <router-view v-if="isRouterAlive"></router-view>
        </el-main>

      </el-container>
    </el-container> -->

 
  </div>
</template>

<script>
  import AllHeaders from './components/Header.vue';//公用头部
  import LeftColumns from './components/Leftcolumn.vue';//左侧边栏

  export default {
    name:'app',
    components:{
      AllHeaders,
      LeftColumns
    },
    provide () {
      return {
        reload: this.reload
      }
    },
    data(){
      return{
        isRouterAlive:true
      }
    },
    methods: {
      reload(){
        this.isRouterAlive = false
        this.$nextTick(function(){
            this.isRouterAlive = true
        })
      }
    },
    
  }
</script>

<style>
  html,body,#app,.el-container{
    padding: 0px;
    margin: 0px;
    height: 100%;
    overflow: hidden;
    font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
  }

  .admin-right,.admin-left{
    background: #edecf2;
  }
</style>
