import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

  const routes = [

    { //首页
      path: '/',
      name: 'home',
      component: () => import('../views/home.vue'),
      children: [
        { //首页
          path: '/',
          name: 'Index',
          component: () => import('../views/content/Index.vue'),
        },
        { //课程列表
          path: '/courselist',
          name: 'Courselist',
          component: () => import('../views/content/Courselist.vue')
        },
        { //课程列表查看
          path: '/coursee/:id/:fr?',
          name: 'Coursee',
          component: () => import('../views/content/Coursee.vue')
        },
        { //课程创建或编辑
          path: '/courbuild/:num/:id?',
          name: 'Courbuild',
          component: () => import('../views/content/Courbuild.vue')
        },
        { //回收站
          path: '/recoverylist',
          name: 'Recoverylist',
          component: () => import('../views/content/Recoverylist.vue')
        },
        { //培训领域
          path: '/trainingfield',
          name: 'Trainingfield',
          component: () => import('../views/content/Trainingfield.vue')
        },
        { //老师列表
          path: '/teacherlist',
          name: 'Teacherlist',
          component: () => import('../views/content/Teacherlist.vue')
        },
        { //证书列表
          path: '/certificatelist',
          name: 'Certificatelist',
          component: () => import('../views/content/Certificatelist.vue')
        },
        { //学员列表
          path: '/studentlist',
          name: 'Studentlist',
          component: () => import('../views/content/Studentlist.vue')
        },
        { //班级列表
          path: '/classlist',
          name: 'Classlist',
          component: () => import('../views/content/Classlist.vue')
        },
        { //关于我们
          path: '/aboutus',
          name: 'Aboutus',
          component: () => import('../views/content/Aboutus.vue')
        },
        { //使用说明
          path: '/instructions',
          name: 'Instructions',
          component: () => import('../views/content/Instructions.vue')
        },
        { //用户协议
          path: '/agreement',
          name: 'Agreement',
          component: () => import('../views/content/Agreement.vue')
        },{ //隐私协议
          path: '/privacyagree',
          name: 'Privacyagree',
          component: () => import('../views/content/Privacyagree.vue')
        },
        { //消息管理
          path: '/message',
          name: 'Message',
          component: () => import('../views/content/Message.vue')
        },
        { //友情链接
          path: '/friendship',
          name: 'Friendship',
          component: () => import('../views/content/Friendship.vue')
        },
        { //联系我们
          path: '/contactus',
          name: 'Contactus',
          component: () => import('../views/content/Contactus.vue')
        },
        { //轮播管理
          path: '/carousel',
          name: 'Carousel',
          component: () => import('../views/content/Carousel.vue')
        },
        { //证书模板
          path: '/certtemplate',
          name: 'Certtemplate',
          component: () => import('../views/content/Certtemplate.vue')
        },
        { //账号管理
          path: '/accountment',
          name: 'Accountment',
          component: () => import('../views/content/Accountment.vue')
        },
        { //角色管理
          path: '/rolelist',
          name: 'Rolelist',
          component: () => import('../views/content/Rolelist.vue')
        },
        { //题库管理
          path: '/questionlist',
          name: 'Questionlist',
          component: () => import('../views/content/Questionlist.vue')
        },
        { //试卷管理
          path: '/paperlist',
          name: 'Paperlist',
          component: () => import('../views/content/Paperlist.vue')
        },
        { //试卷创建和编辑
          path: '/paperbuild/:num/:id?',
          name: 'Paperbuild',
          component: () => import('../views/content/Paperbuild.vue')
        },
        { //个人中心
          path: '/Personal',
          name: 'Personal',
          component: () => import('../views/content/Personal.vue')
        },
      ]
    },
    { //登录页面
      path: '/login',
      name: 'login',
      component: () => import('../views/login.vue')
    }
  ]


const router = new VueRouter({
  routes
})

export default router
